<template>
  <div class="row" id="client-user">
    <div class="col-md-8 offset-2">
      <div class="card form-body">
        <div class="card-body">
          <div class="d-flex">
            <legend><h6>User Information</h6></legend>
            <div class="form-group" style="width: 15%">
              <label for="">Admin <span class="text-danger">*</span></label>
              <span class="switch">
                <input
                  type="checkbox"
                  v-model="is_admin"
                  class="switch"
                  id="switch-normal_user_admin"
                />
                <label for="switch-normal_user_admin"></label>
              </span>
            </div>
            <div class="form-group ml-8" style="width: 15%">
              <label for="">Active <span class="text-danger">*</span></label>
              <span class="switch">
                <input
                  type="checkbox"
                  v-model="is_active"
                  class="switch"
                  id="switch-normal_user_new"
                />
                <label for="switch-normal_user_new"></label>
              </span>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for=""
                  >Username <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  :disabled="isEdit"
                  v-model="username"
                  :class="errors['username'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['username']" class="text-danger">{{
                  errors["username"][0]
                }}</span>
              </div>
            </div>

            <div class="col-md-6" v-if="!isEdit">
              <div class="form-group">
                <label for=""
                  >Password <span class="text-danger">*</span></label
                >
                <input
                  :type="password_type"
                  name=""
                  class="form-control"
                  id="password"
                  v-model="password"
                  :class="errors['password'] ? 'border border-danger' : ''"
                />
                <a @click="showPassword()" style="cursor: pointer">
                  <i
                    :class="
                      showPasswordIcon
                        ? 'fa fa-eye text-info'
                        : 'fa fa-eye-slash'
                    "
                    style="position: absolute; right: 2rem; top: 2.5rem"
                  ></i
                ></a>
                <span v-if="errors['password']" class="text-danger">{{
                  errors["password"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for=""
                  >First Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="first_name"
                  :class="errors['first_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['first_name']" class="text-danger">{{
                  errors["first_name"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for=""
                  >Last Name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="last_name"
                  :class="errors['last_name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['last_name']" class="text-danger">{{
                  errors["last_name"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6" v-if="!isEdit">
              <div class="form-group">
                <label for="">Email <span class="text-danger">*</span></label>
                <input
                  type="email"
                  name=""
                  class="form-control"
                  id="email"
                  v-model="email"
                  :class="errors['email'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['email']" class="text-danger">{{
                  errors["email"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="">Role <span class="text-danger">*</span></label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="roles"
                >
                  <option value="All">All</option>
                  <option value="Analyst">Analyst</option>
                  <option value="Approver">Approver</option>
                  <option value="Oven Supervisor">Oven Supervisor</option>
                  <option value="Shift Incharge">Shift Incharge</option>
                  <option value="None" selected>None</option>
                </select>
                <span v-if="errors['roles']" class="text-danger">{{
                  errors["roles"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for=""
                  >Choose Modules <span class="text-danger"></span
                ></label>
                <multiselect
                  :options="modules"
                  :multiple="true"
                  placeholder="Choose Modules"
                  v-model="user_modules"
                  label="label"
                  track-by="label"
                ></multiselect>
              </div>
            </div>
          </div>

          <!-- <div class="form-group">
            <label for="">Username <span class="text-danger">*</span></label>
            <input
              type="text"
              name=""
              class="form-control"
              id=""
              v-model="username"
              :class="errors['username'] ? 'border border-danger' : ''"
            />
            <span v-if="errors['username']" class="text-danger">{{
              errors["username"][0]
            }}</span>
          </div> -->
        </div>
        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            @click="store('S', 'kt_save_service')"
            ref="kt_save_service"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('SAA', 'kt_save_and_add_service')"
            ref="kt_save_and_add_service"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import {
  STORE_B_USER,
  UPDATE_B_USER,
  GET_ORGANIZATION_USER_DATA,
  GET_MODULES,
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      isEdit: false,
      modules: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Users",
          route: "client.user",
        },
        {
          id: 3,
          title: "Create New User",
          route: "",
        },
      ],
      errors: [],
      user_modules: [],
      //form-data
      username: "",
      roles: "None",
      email: "",
      first_name: "",
      last_name: "",
      password: "",
      password_type: "password",
      showPasswordIcon: false,
      is_admin: false,
      is_active: true,
    };
  },
  components: { Multiselect },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    this.isEdit = this.$route.name == "client.user.edit" ? true : false;
    var id = this.$route.params.id;

    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);

    this.$store.dispatch(GET_MODULES).then((data) => {
      this.modules = data.data;
    });

    if (this.isEdit) {
      this.breadCrumbs[2].title = "";
      this.$store.dispatch(GET_ORGANIZATION_USER_DATA, id).then((data) => {
        this.breadCrumbs[2].title = "Edit User";
        this.name = data.data.name;
        this.roles = data.data.roles;
        this.user_modules = data.data.user_modules;
        this.is_active = data.data.is_active;
        this.username = data.data.username;
        this.first_name = data.data.first_name;
        this.last_name = data.data.last_name;
        this.email = data.data.email;
        this.is_admin = data.data.is_admin;
        this.roles = data.data.roles;
      });
    }
  },
  methods: {
    showPassword() {
      if (!this.showPasswordIcon) {
        this.showPasswordIcon = true;
        this.password_type = "text";
      } else {
        this.showPasswordIcon = false;
        this.password_type = "password";
      }
    },
    goBack() {
      this.$router.push({
        name: "client.user",
      });
    },

    clearForm() {
      //form-data
      this.username = "";
      this.first_name = "";
      this.last_name = "";
      this.user_modules = [];
      this.email = "";
      this.password = "";
      this.is_admin = false;
      this.roles = "";
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
    store(saveType, ref) {
      this.errors = [];

      this.loadingButton(ref);
      var _data = {
        id: this.$route.params.id,
        username: this.username,
        password: this.password,
        email: this.email,
        user_modules: this.user_modules,
        first_name: this.first_name,
        last_name: this.last_name,
        roles: this.roles,
        is_admin: this.is_admin,
        is_active: this.is_active,
      };
      // _data.client_slug = client_slug;
      // console.log(this.errors);

      this.$store
        .dispatch(STORE_B_USER, _data)
        .then((data) => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({
              name: "client.user",
            });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch((err) => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    update(ref) {
      this.errors = [];
      this.loadingButton(ref);
      var _data = {
        id: this.$route.params.id,
        username: this.username,
        first_name: this.first_name,
        last_name: this.last_name,
        roles: this.roles,
        user_modules: this.user_modules,
        is_admin: this.is_admin,
        is_active: this.is_active,
      };

      this.$store
        .dispatch(UPDATE_B_USER, _data)
        .then((data) => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);

          var client_slug = this.$route.params.client_slug;
          this.$router.push({
            name: "client.user",
            params: { client_slug: client_slug },
          });
        })
        .catch((err) => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
  },
};
</script>

<style></style>
